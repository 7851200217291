jQuery(document).ready(function ($) {
	$(window).on('load', function () {
		var env = 'prod';
		var isMobile = window.matchMedia('(max-width: 768px)').matches;
		var arrow1 = templateUrl + '/images/Rectangle-39.png';
		var arrow2 = templateUrl + '/images/Rectangle-37.png';
		var left_arrow = templateUrl + '/images/button-left-arrow.svg';
		var right_arrow = templateUrl + '/images/button-right-arrow.svg';
		var left_arrow_mobile = templateUrl + '/images/mobile-left-arrow.svg';
		var right_arrow_mobile = templateUrl + '/images/mobile-right-arrow.svg';

		// Footer Location Dropdown
		$('.footer-global .toggle').on('click', function () {
			$(this).toggleClass('open');
			if ($(this).hasClass('open')) {
				$('.footer-global--locations--container').slideDown();
			} else {
				$('.footer-global--locations--container').hide();
			}
		});

		// Register Homepage Sliders
		if ($('body').hasClass('home')) {
			$('#slider-home [data-slick]').on(
				'init',
				function (event, slick, currentSlide, nextSlide) {
					const textContainer = document
						.querySelector('.slick-active .slider-home__text')
						.getBoundingClientRect().height;
					const nextArrow =
						document.querySelector('.slick-next-mobile');
					if (nextArrow) {
						nextArrow.style.top = `${textContainer - 50}px`;
					}
				}
			);

			$('#slider-home [data-slick]').slick({
				autoplay: true,
				autoplaySpeed: 3000,
				prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${arrow1}'></div>`,
				nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${arrow2}'></div><div class='slick-next-mobile'><img class='a-right control-c next' src='${arrow2}'></div>`,
			});

			$('#slider-home [data-slick]').on(
				'afterChange',
				function (event, slick, currentSlide, nextSlide) {
					const textContainer = document
						.querySelector('.slick-active .slider-home__text')
						.getBoundingClientRect().height;
					const nextArrow =
						document.querySelector('.slick-next-mobile');

					if (nextArrow) {
						nextArrow.style.top = `${textContainer - 50}px`;
					}
				}
			);

			$('#cta-features [data-slick]').slick({
				centerMode: true,
				centerPadding: '0 20px',
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000,
				infinite: true,
				dots: false,
				prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${arrow1}'></div>`,
				nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${arrow2}'></</div>`,
				mobileFirst: true,
				responsive: [
					{
						breakpoint: 641,
						settings: 'unslick',
					},
				],
			});
			// Re-initialize slider on changes
			$(window).on('load resize orientationchange', function () {
				if (
					$('#cta-features [data-slick]').hasClass(
						'slick-initialized'
					)
				) {
					$('#cta-features [data-slick]').slick('unslick');
				}
				$('#cta-features [data-slick]').slick({
					centerMode: true,
					centerPadding: '0 20px',
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 3000,
					infinite: true,
					dots: false,
					prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${arrow1}'></div>`,
					nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${arrow2}'></</div>`,
					mobileFirst: true,
					responsive: [
						{
							breakpoint: 641,
							settings: 'unslick',
						},
					],
				});
			});

			// Initial text animation load
			$('[data-slick-index]').addClass('active');
		}

		// Register Menu Sliders
		if ($('.menu__nav')) {
			// Menu Nav
			if ($('.top-menu-nav').length) {
				$('.top-menu-nav').on('click', function (e) {
					$(this).toggleClass('top-menu-nav--opened');
					e.stopPropagation();
				});

				$(document).click(function () {
					$('.top-menu-nav').removeClass('top-menu-nav--opened');
				});
			}

			$('.menu__nav').on('click', function () {
				$('.menu__nav').toggleClass('open');
				$('.menu__nav').attr('aria-expanded', function (i, attr) {
					return attr == 'false' ? 'true' : 'false';
				});
				$('.menu__link').toggleClass('open');
			});

			// Menu Slide Small
			if ($('.menu-slide--small').length) {
				$('.menu-slide--small').slick({
					autoplay: false,
					slidesToShow: 4,
					variableWidth: true,
					slidesToScroll: 4,
					infinite: false,
					prevArrow: `<div class='slick-prev'><img class='a-right control-c next' src='${left_arrow}'></div>`,
					nextArrow: `<div class='slick-next'><span><img class='a-right control-c next' src='${right_arrow}'></span></div>`,
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1,
							},
						},
					],
				});
			}

			// Menu Slide Large
			if (isMobile) {
				if ($('.menu-slide--no-image').length) {
					$('.menu-slide--no-image').slick({
						autoplay: false,
						slidesToShow: 2,
						variableWidth: true,
						slidesToScroll: 1,
						infinite: false,
						slickSetOption: {
							refresh: true,
						},
						prevArrow: `<div class='slick-prev'><img class='a-right control-c next' src='${left_arrow}'></div>`,
						nextArrow: `<div class='slick-next'><span><img class='a-right control-c next' src='${right_arrow}'></span></div>`,
					});
				}
			}

			// Menu Submenu
			if ($('.menu-submenu').length) {
				$('.menu-submenu').slick({
					autoplay: false,
					slidesToShow: 1,
					infinite: false,
					focusOnSelect: true,
					variableWidth: true,
					prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${left_arrow_mobile}'></div>`,
					nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${right_arrow_mobile}'></div>`,
				});

				$('.menu-submenu').on(
					'beforeChange',
					function (event, slick, currentSlide, nextSlide) {
						var currentContent = $('.menu__data--actived').children(
							'.menu-section'
						)[nextSlide];
						$('html, body').animate({
							scrollTop: $(currentContent).offset().top - 220,
						});
					}
				);

				var eleHeight = $('.menu__left').offset().top;

				if (isMobile) {
					$(document).scroll(function () {
						var scrollHeight = $(document).scrollTop();

						if (eleHeight - scrollHeight - 123 <= 0) {
							$('.menu__left').addClass('menu__left--fixed');
							$('.menu__right').addClass(
								'menu__right--top-adjust'
							);
						} else {
							$('.menu__left').removeClass('menu__left--fixed');
							$('.menu__right').removeClass(
								'menu__right--top-adjust'
							);
						}
					});
				}
			}

			// Menu Content
			if ($('.menu__content ').hasClass('mobile')) {
				$('.sticky').css('top', '40px');
			}

			// Menu Item
			if (!$('.menu__item--actived').length) {
				$('.menu__item').first().addClass('menu__item--actived');
				$('.menu-submenu').first().addClass('menu-submenu--actived');
				$('.menu__data').removeClass('menu__data--actived');
				$('.menu__item').first().click();
				$('.menu__data')
					.first()
					.addClass('menu__data--actived')
					.attr('aria-hidden', 'false');
			}

			// Menu Link
			$('.menu__link').on('click', function () {
				const curSlug = $(this).data('slug');

				if (curSlug === 'breakfast-and-brunch') {
					$('.menu__right').append(
						'<div class="menu__right--breakfast"><img src="https://tastybistro.ca/wp-content/uploads/2024/06/TASTY-BRUCH-622x1024.jpg"/></div>'
					);
				} else if ($('.menu__right--breakfast').length) {
					$('.menu__right--breakfast').remove();
				}

				$('.menu__data').removeClass('menu__data--actived');
				$('.menu__data').attr('aria-hidden', 'true');

				$(`.menu__data[data-title=${curSlug}]`)
					.addClass('menu__data--actived')
					.attr('aria-hidden', 'false');
				$('.menu__item').removeClass('menu__item--actived');
				$(this).parent('.menu__item').addClass('menu__item--actived');

				if ($('.menu-submenu').length) {
					$('.menu-submenu').removeClass('menu-submenu--actived');
					$(`.menu-submenu[data-parent=${curSlug}]`).addClass(
						'menu-submenu--actived'
					);
				}

				$('html, body').animate(
					{
						scrollTop: $('.menu__data--actived').offset().top - 204,
					},
					'smooth'
				);

				setTimeout(function () {
					$('.menu-slide--small').slick('resize');
					$('.menu-slide--large').slick('refresh');
				}, 100);

				$('.menu-slide--no-image').slick('resize');
				$('.menu-slide--no-image').slick('setPosition');
			});

			// Menu Slide Large
			if ($('.menu-slide--large').length) {
				$('.menu-slide--large').slick({
					autoplay: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: false,
					prevArrow: `<div class='slick-prev'><img class='a-left control-c prev' src='${left_arrow}'></div>`,
					nextArrow: `<div class='slick-next'><img class='a-right control-c next' src='${right_arrow}'></div>`,
				});
			}
		}

		// Sub Menu Handler
		$('.menu-item-has-children').on('click', function (e) {
			$('.menu-item-has-children').each(function (item) {
				if (item != $(this)) {
					$(this).children('.sub-menu').removeClass('open');
				}
			});
			$(this).children('a').first().toggleClass('open');
			$(this).children('.sub-menu').toggleClass('open');

			if ($(window).width() < 1021 && !$('.mobile-nav-back').length) {
				$('.sub-menu.open').prepend(function () {
					return $(
						'<li><a href="javascript:void(0)" class="mobile-nav-back">BACK</a><li>'
					).click(mobileCloseSubNav);
				});
			}
		});

		$('.sub-menu.open').on('click', function (e) {
			e.preventDefault();
		});

		// Mobile Menu Handler
		$('.hamburger').on('click', function () {
			resetMenu();
			$(this).toggleClass('open');
			$('.nav').toggleClass('open');
		});

		$('.sub-menu')
			.find('a')
			.on('click', function (e) {
				$('.hamburger').toggleClass('open');
				$('.nav').toggleClass('open');
				e.stopPropagation();
			});

		//
		if ($('#menu-main-menu')) {
			$('#menu-main-menu')
				.find('.menu-item-has-children')
				.last()
				.find('ul')
				.css({ width: '100px', left: 0 });
		}

		function mobileCloseSubNav() {
			$('.mobile-nav-back').parent().remove();
		}

		function resetMenu() {
			$('.menu-item-has-children').find('open').removeClass('open');
			$('.sub-menu').removeClass('open');
			$('.mobile-nav-back').parent().remove();
		}

		// Sauce page accordion
		if ($('body').hasClass('page-sauces')) {
			$('.location__title').on('click', function () {
				$(this).parent().children().toggleClass('active');
			});
		}

		// Private Dining

		$('#field_10_10').on('change', function () {
			$('.form').removeClass('initial');
		});

		AOS.refresh();
	});
});
